require("@babel/polyfill");
global.jQuery = global.$ = require("jquery");
enquire = require("enquire.js");
slick = require("slick-carousel");
SmoothScroll = require("smooth-scroll");

$.ui = require('jquery-ui/ui/widget.js');

require('jquery-ui/ui/widgets/datepicker');

require('jquery-ui/ui/i18n/datepicker-en-GB');
require('jquery-ui/ui/i18n/datepicker-de');


require("./bigtext.js");


function shrinkHeader() {
    var headerCta = document.getElementsByClassName("c-header__cta")[0];
    var toTop = document.getElementsByClassName("c-to-top")[0];
    if (window.pageYOffset > 170) {
        headerCta.classList.add("c-header__cta--minimize");
        headerCta.classList.toggle("c-header__cta--add-animation", true);
        toTop.classList.add("c-to-top--visible");
    } else {
        headerCta.classList.remove("c-header__cta--minimize");
        toTop.classList.remove("c-to-top--visible");
    }
}

function fixTocMT() {
    var header = document.getElementsByClassName("c-header")[0],
        pagenav = document.getElementsByClassName("c-pagenav")[0],
        pagenavSidebar = document.getElementsByClassName("c-pagenav__sidebar")[0],
        pagenavLift = document.getElementsByClassName("c-pagenav__lift")[0],
        pagenavToggler = document.getElementsByClassName("c-pagenav__toggler")[0],
        pagenavTop = pagenav.getBoundingClientRect().top,
        headerHeight = header.getBoundingClientRect().height,
        extraHeight = 0,
        finalHeight = headerHeight + extraHeight;

    if (pagenavTop <= finalHeight) {
        pagenav.classList.add("c-pagenav--vanish");
        if (!pagenavSidebar.classList.contains("c-pagenav__sidebar--show")) {
            pagenavSidebar.classList.add("c-pagenav__sidebar--show");
            pagenavToggler.classList.add("c-pagenav__toggler--closed");
            pagenavLift.classList.add("c-pagenav__lift--vanish");
        }
    } else if (pagenavTop >= finalHeight) {
        pagenav.classList.remove("c-pagenav--vanish", "c-pagenav--vanish-active");
        pagenavLift.classList.remove("c-pagenav__lift--vanish");
    }
}

function fixTocD() {
    var header = document.getElementsByClassName("c-header")[0],
        pagenav = document.getElementsByClassName("c-pagenav")[0],
        pagenavSidebar = document.getElementsByClassName("c-pagenav__sidebar")[0],
        pagenavLift = document.getElementsByClassName("c-pagenav__lift")[0],
        pagenavToggler = document.getElementsByClassName("c-pagenav__toggler")[0],
        pagenavTop = pagenav.getBoundingClientRect().top,
        headerHeight = header.getBoundingClientRect().height,
        extraHeight = 20,
        finalHeight = headerHeight + extraHeight;

    if (pagenavTop <= finalHeight) {
        pagenav.classList.add("c-pagenav--vanish");
        if (!pagenavSidebar.classList.contains("c-pagenav__sidebar--show")) {
            pagenavSidebar.classList.add("c-pagenav__sidebar--show");
            pagenavToggler.classList.add("c-pagenav__toggler--closed");
            pagenavLift.classList.add("c-pagenav__lift--vanish");
        }
    } else if (pagenavTop >= finalHeight) {
        pagenav.classList.remove("c-pagenav--vanish", "c-pagenav--vanish-active");
        pagenavLift.classList.remove("c-pagenav__lift--vanish");
    }
}

function fixTocW() {
    var header = document.getElementsByClassName("c-header")[0],
        pagenav = document.getElementsByClassName("c-pagenav")[0],
        pagenavSidebar = document.getElementsByClassName("c-pagenav__sidebar")[0],
        pagenavLift = document.getElementsByClassName("c-pagenav__lift")[0],
        pagenavToggler = document.getElementsByClassName("c-pagenav__toggler")[0],
        pagenavTop = pagenav.getBoundingClientRect().top,
        headerHeight = header.getBoundingClientRect().height,
        extraHeight = 75,
        finalHeight = headerHeight + extraHeight;

    if (pagenavTop <= finalHeight) {
        pagenav.classList.add("c-pagenav--vanish");
        if (!pagenavSidebar.classList.contains("c-pagenav__sidebar--show")) {
            pagenavSidebar.classList.add("c-pagenav__sidebar--show");
            pagenavToggler.classList.add("c-pagenav__toggler--closed");
            pagenavLift.classList.add("c-pagenav__lift--vanish");
        }
    } else if (pagenavTop >= finalHeight) {
        pagenav.classList.remove("c-pagenav--vanish", "c-pagenav--vanish-active");
        pagenavLift.classList.remove("c-pagenav__lift--vanish");
    }
}

function pagenavShowHide() {
    var pagenav = document.getElementsByClassName("c-pagenav")[0];
    var pagenavToggler = document.getElementsByClassName("c-pagenav__toggler")[0];
    var pagenavLift = document.getElementsByClassName("c-pagenav__lift")[0];

    pagenavToggler.addEventListener("click", function() {
        if (!pagenav.classList.contains("c-pagenav--vanish")) {
            pagenav.classList.add("c-pagenav--vanish");
        }
        pagenav.classList.toggle("c-pagenav--vanish-active");
        pagenavLift.classList.toggle("c-pagenav__lift--vanish");
        pagenavToggler.classList.toggle("c-pagenav__toggler--closed");
    })
}

function mobileMenuTrigger() {
    if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(".c-header__menu").removeClass("c-header__menu--active");
        //$('.c-page').removeClass('c-page--menu-active');
        //$('body').removeClass('body--menu-active');
    } else {
        $(this).addClass("active");
        $(".c-header__menu").addClass("c-header__menu--active");
        //$('.c-page').addClass('c-page--menu-active');
        //$('body').addClass('body--menu-active');
    }
}

$(function() {
    window.addEventListener("scroll", shrinkHeader);

    // slick slider with top and bottom in content
    if ($(".c-slider").length) {
        $(".c-slider__top").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            fade: true,
            autoplaySpeed: 8000,
            speed: 1000,
            dots: false,
            arrows: true,
            zIndex: 20,
            asNavFor: ".c-slider__bottom",
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 719,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        });

        $(".c-slider__bottom").slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            fade: false,
            autoplaySpeed: 8000,
            speed: 1000,
            dots: false,
            arrows: false,
            zIndex: 20,
            asNavFor: ".c-slider__top",
            centerMode: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 719,
                    settings: {
                        arrows: false,
                        centerMode: false
                    }
                }
            ]
        });
    }

    // Slider for magazine
    if ($(".c-heroslider").length) {
        $(".c-heroslider__layout").slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            fade: false,
            autoplaySpeed: 8000,
            speed: 1000,
            dots: false,
            arrows: true,
            zIndex: 20,
            variableWidth: true,

            responsive: [
                {
                    breakpoint: 719,

                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        variableWidth: true
                    }
                }
            ]
        });
    }

    if ($(".c-slider--small").length) {
        $(".c-slider--small").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 8000,
            speed: 1000,
            dots: true,
            arrows: false,
            zIndex: 20,
            centerMode: false
        });
    }

    if ($(".c-hero--slider").length) {
        $(".c-hero--slider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            fade: false,
            autoplaySpeed: 8000,
            speed: 1000,
            dots: true,
            arrows: false,
            zIndex: 20,
            centerMode: false,
            mobileFirst: true,

            responsive: [
                {
                    breakpoint: 719,

                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        arrows: true,
                        slidesToShow: 1,
                        // variableWidth: true
                    }
                }
            ]
        });
    }

    if ($(".c-social__list").length) {
        $(".c-social__list").slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: false,
            fade: false,
            autoplaySpeed: 8000,
            speed: 1000,
            dots: false,
            arrows: true,
            zIndex: 20,
            centerMode: false,

            responsive: [
                {
                    breakpoint: 719,

                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,

                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1279,

                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }
            ]
        });
    }

    // Prevent Mapscroll when scrolling over map
    function mapScroll() {
        if ($(".c-location__mapembed").length) {
            $(".c-location__mapembed")
                .click(function() {
                    $(this)
                        .find(".c-location__mapiframe")
                        .addClass("c-location__mapiframe--scroll-enable");
                })
                .mouseleave(function() {
                    $(this)
                        .find(".c-location__mapiframe")
                        .removeClass("c-location__mapiframe--scroll-enable");
                });
        }
    }

    // two click video loader
    var iframePrivacy = function() {
        var privacyEl = document.querySelectorAll(".js-iframe-privacy");
        if (!privacyEl.length) {
            return;
        }
        Array.prototype.forEach.call(privacyEl, function(el, index, array) {
            el.addEventListener("click", function() {
                var iframe = el.querySelector("iframe"),
                    iframeSrc = iframe.getAttribute("data-src");
                el.classList.add("iframe-loaded");
                iframe.setAttribute("src", iframeSrc);
            });
        });
    };

    // two click video loader
    var mapPrivacy = function() {
        var privacyEl = document.querySelectorAll(".js-map-privacy");
        if (!privacyEl.length) {
            return;
        }
        Array.prototype.forEach.call(privacyEl, function(el, index, array) {
            el.addEventListener("click", function() {
                var iframe = el.querySelector("iframe"),
                    iframeSrc = iframe.getAttribute("data-src");
                el.classList.add("iframe-loaded");
                iframe.setAttribute("src", iframeSrc);
                mapScroll();
            });
        });
    };

    window.onload = function() {
        iframePrivacy();
        mapPrivacy();
    };

    // generate toc if class is set on main and handle minimize state
    if (document.querySelector(".js-generate-toc") !== null) {
        var contentSection = document.getElementsByClassName("c-section");
        var firstContentSection = contentSection[0];
        var tocMarkup =
            '<div class="c-pagenav__sidebar"><div class="c-pagenav__toggler"><svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" class="svg-inline--fa fa-times fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path></svg></div><nav class="c-pagenav__lift"><span class="c-pagenav__list-title">Seiteninhalt</span><ul class="c-pagenav__lift-list"></ul></nav></div><div class="c-pagenav"><div class="c-pagenav__layout"><div class="c-pagenav__wrapper"><span class="c-pagenav__title">Seiteninhalt</span><nav class="c-pagenav__menu"><ul class="c-pagenav__wrap"></ul></nav></div></div></div>';
        firstContentSection.insertAdjacentHTML("beforebegin", tocMarkup);

        var pageEl = document.querySelector('.c-page');
        pageEl.insertAdjacentHTML("afterbegin", '<div class="c-pagenav__observer" style="position: absolute; top: 0; left: 0; width: 1px; background: transparent;"></div>')
        var pageNavSidebar = document.querySelector('.c-pagenav__sidebar')
        var pageNavObserver = document.querySelector('.c-pagenav__observer')
        pageNavObserver.style.height = (pageNavSidebar.getBoundingClientRect().top + window.pageYOffset - parseInt(getComputedStyle(pageNavSidebar).top, 10)) + 'px';
        var observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (!entry.isIntersecting) {
                    pageNavSidebar.style.position = 'fixed'
                    observer.unobserve(pageNavObserver)
                }
            })
        }, {});
        observer.observe(pageNavObserver)

        var tocMenu = document.getElementsByClassName("c-pagenav__wrap")[0];
        var tocLift = document.getElementsByClassName("c-pagenav__lift-list")[0];

        // sanitize text for id generating
        function deUmlaut(value) {
            value = value.toLowerCase();
            value = value.replace(/ä/g, "ae");
            value = value.replace(/ö/g, "oe");
            value = value.replace(/ü/g, "ue");
            value = value.replace(/ß/g, "ss");
            value = value.replace(/ /g, "-");
            value = value.replace(/\./g, "");
            value = value.replace(/\?/g, "");
            value = value.replace(/\!/g, "");
            value = value.replace(/,/g, "");
            value = value.replace(/\(/g, "");
            value = value.replace(/\)/g, "");
            return value;
        }

        // find h2 and set id, sanitize text, build menu and append to menu wrap
        var tags =  document.getElementsByClassName("h2-page-nav-item");
        for (var i = 0, h2; (h2 = tags[i]); i++) {
            h2.id = deUmlaut(h2.innerHTML);
            h2.classList.add("u-toc-offset");
            h2.classList.add("js-toc-target");

            var tocItem = document.createElement("li");
            tocItem.className = "c-pagenav__item";
            var tocLink = document.createElement("a");
            tocLink.className = "c-pagenav__link";
            tocLink.innerHTML = h2.innerHTML;
            tocLink.href = "#" + deUmlaut(h2.innerHTML);
            tocItem.appendChild(tocLink);
            tocMenu.appendChild(tocItem);

            var tocLiftItem = document.createElement("li");
            tocLiftItem.className = "c-pagenav__lift-item";
            var tocLiftLink = document.createElement("a");
            tocLiftLink.className = "c-pagenav__lift-link";
            // tocLiftLink.innerHTML = h2.innerHTML;
            tocLiftLink.href = "#" + deUmlaut(h2.innerHTML);
            var tocLiftInnerLink = document.createElement("span")
            tocLiftInnerLink.innerHTML = h2.innerHTML;
            tocLiftLink.appendChild(tocLiftInnerLink)
            tocLiftItem.appendChild(tocLiftLink);
            tocLift.appendChild(tocLiftItem);
        }

        // scrollspy
        var sections = document.getElementsByClassName("js-toc-target");
        var navLiftLinks = document.getElementsByClassName("c-pagenav__lift-link");
        var navLinks = document.getElementsByClassName("c-pagenav__link");

        function getClosestSection() {
            var sectionsLength = sections.length;
            for (var index = 0; index < sectionsLength; index++) {
                if (isBelowScroll(sections.item(index))) break;
            }
            if (sections.item(index) != null){
                selectLiftLink(sections.item(index).id);
                selectLink(sections.item(index).id);
            }
        }

        function isBelowScroll(element) {
            var position = element.getBoundingClientRect();
            return position.top > 0;
        }

        function selectLiftLink(id) {
            Array.prototype.forEach.call(navLiftLinks, function(element) {
                element.classList.remove("c-pagenav__lift-link--active");
            });

            document
                .querySelector('a.c-pagenav__lift-link[href="#' + id + '"]')
                .classList.add("c-pagenav__lift-link--active");
        }

        function selectLink(id) {
            Array.prototype.forEach.call(navLinks, function(element) {
                element.classList.remove("c-pagenav__link--active");
            });

            document
                .querySelector('a.c-pagenav__link[href="#' + id + '"]')
                .classList.add("c-pagenav__link--active");
        }

        window.addEventListener("scroll", function(event) {
            getClosestSection();
        });

        // smooth scroll
        var scroll = new SmoothScroll('a[href*="#"]', {
            speed: 500
        });

        enquire.register("screen and (max-width: 1024px)", {
            match: function() {
                window.addEventListener("scroll", fixTocMT);
            },
            unmatch: function() {
                window.removeEventListener("scroll", fixTocMT);
            }
        });

        enquire.register("screen and (min-width: 1025px) and (max-width: 1279px)", {
            match: function() {
                window.addEventListener("scroll", fixTocD);
            },
            unmatch: function() {
                window.removeEventListener("scroll", fixTocD);
            }
        });

        enquire.register("screen and (min-width: 1280px)", {
            match: function() {
                window.addEventListener("scroll", fixTocW);
            },
            unmatch: function() {
                window.removeEventListener("scroll", fixTocW);
            }
        });

        pagenavShowHide();
    }

    // initialise bubbleimage
    if (document.querySelectorAll(".c-bubbleimage") !== null) {
        var origin = document.getElementsByClassName("c-bubbleimage__dot");
        var target = document.getElementsByClassName("c-bubbleimage__infobox");
        var targetwrap = document.getElementsByClassName(
            "c-bubbleimage__infowrap"
        )[0];

        for (var i = 0; i < origin.length; i++) {
            (function(index) {
                origin[index].addEventListener("click", function(e) {
                    console.log("you clicked region number " + index);
                    target[index].classList.add("visible");
                    targetwrap.classList.add("visible");
                    e.preventDefault();
                });
                targetwrap.addEventListener("click", function() {
                    target[index].classList.remove("visible");
                    targetwrap.classList.remove("visible");
                });
            })(i);
        }
    }

    // do stuff in mobile
    enquire.register("screen and (max-width: 1024px)", {
        match: function() {
            // open close the menu on mobile on click trigger
            $(".hamburger").on("click", mobileMenuTrigger);

            //menu logic for mobile when submenu available
            $(".c-menu-main__item--has-sub")
                .children(".c-menu-main__link")
                .append('<span class="c-menu-main__link-subtrigger"></span>');
            $(".c-menu-main__item-l1--has-sub")
                .children(".c-menu-main__link-l1")
                .append('<span class="c-menu-main__link-l1-subtrigger"></span>');

            $(".c-menu-main__link-subtrigger").bind("click", function(e) {
                if (
                    $(this)
                        .parents(".c-menu-main__item--has-sub")
                        .hasClass("c-menu-main__item--has-sub--is-active")
                ) {
                    $(this).removeClass("c-menu-main__link-subtrigger--is-active");
                    $(this)
                        .parents(".c-menu-main__item--has-sub")
                        .removeClass("c-menu-main__item--has-sub--is-active");
                    $(this)
                        .parents(".c-menu-main__item--has-sub")
                        .children(".c-menu-main__l1-wrap--open")
                        .removeClass("c-menu-main__l1-wrap--open")
                        .slideUp();
                } else {
                    $(".c-menu-main__link-subtrigger").removeClass(
                        "c-menu-main__link-subtrigger--is-active"
                    );
                    $(this).addClass("c-menu-main__link-subtrigger--is-active");
                    $(".c-menu-main__item--has-sub").removeClass(
                        "c-menu-main__item--has-sub--is-active"
                    );
                    $(".c-menu-main__l1-wrap--open")
                        .removeClass("c-menu-main__l1-wrap--open")
                        .slideUp();
                    $(".c-menu-main__item-l1--has-sub").removeClass(
                        "c-menu-main__item-l1--has-sub--is-active"
                    );
                    $(".c-menu-main__list-l2--open")
                        .removeClass("c-menu-main__list-l2--open")
                        .slideUp();
                    $(".c-menu-main__link-l1-subtrigger").removeClass(
                        "c-menu-main__link-l1-subtrigger--is-active"
                    );
                    $(this)
                        .parents(".c-menu-main__item--has-sub")
                        .addClass("c-menu-main__item--has-sub--is-active");
                    $(this)
                        .parents(".c-menu-main__item--has-sub")
                        .children(".c-menu-main__l1-wrap")
                        .addClass("c-menu-main__l1-wrap--open")
                        .slideDown();
                }
                e.preventDefault();
            });

            $(".c-menu-main__link-l1-subtrigger").bind("click", function(e) {
                if (
                    $(this)
                        .parents(".c-menu-main__item-l1--has-sub")
                        .hasClass("c-menu-main__item-l1--has-sub--is-active")
                ) {
                    $(this).removeClass("c-menu-main__link-l1-subtrigger--is-active");
                    $(this)
                        .parents(".c-menu-main__item-l1--has-sub")
                        .removeClass("c-menu-main__item-l1--has-sub--is-active");
                    $(this)
                        .parents(".c-menu-main__item-l1--has-sub")
                        .children(".c-menu-main__list-l2--open")
                        .removeClass("c-menu-main__list-l2--open")
                        .slideUp();
                } else {
                    $(".c-menu-main__link-l1-subtrigger").removeClass(
                        "c-menu-main__link-l1-subtrigger--is-active"
                    );
                    $(this).addClass("c-menu-main__link-l1-subtrigger--is-active");
                    $(".c-menu-main__item-l1--has-sub").removeClass(
                        "c-menu-main__item-l1--has-sub--is-active"
                    );
                    $(".c-menu-main__list-l2--open")
                        .removeClass("c-menu-main__list-l2--open")
                        .slideUp();
                    $(this)
                        .parents(".c-menu-main__item-l1--has-sub")
                        .addClass("c-menu-main__item-l1--has-sub--is-active");
                    $(this)
                        .parents(".c-menu-main__item-l1--has-sub")
                        .children(".c-menu-main__list-l2")
                        .addClass("c-menu-main__list-l2--open")
                        .slideDown();
                }
                e.preventDefault();
            });

            // Content Toggle for mobile
            if ($(".js-settoggle-mobile").length) {
                $(".js-gettoggle-mobile").hide();
                $(".js-settoggle-mobile").bind("click", function(e) {
                    if (
                        $(".js-settoggle-mobile").hasClass("js-settoggle-mobile--active")
                    ) {
                        $(this).removeClass("js-settoggle-mobile--active");
                        $(this)
                            .next(".js-gettoggle-mobile")
                            .removeClass("js-gettoggle-mobile--active")
                            .slideUp();
                    } else {
                        $(this).addClass("js-settoggle-mobile--active");
                        $(this)
                            .next(".js-gettoggle-mobile")
                            .addClass("js-gettoggle-mobile--active")
                            .slideDown();
                    }
                });
            }

            // $('.c-pagenav__toggler').on('click', function (){
            //     if($(this).hasClass('c-pagenav__toggler--active')) {
            //         $(this).removeClass('c-pagenav__toggler--active');
            //         $('.c-pagenav__wrapper').removeClass('c-pagenav__wrapper--active');
            //     } else {
            //         $(this).addClass('c-pagenav__toggler--active');
            //         $('.c-pagenav__wrapper').addClass('c-pagenav__wrapper--active');
            //     }
            // });
        },

        unmatch: function() {
            $(".js-settoggle-mobile").off("click");
            $(".js-settoggle-mobile").removeClass("js-settoggle-mobile--active");
            $(".js-gettoggle-mobile")
                .removeClass("js-gettoggle-mobile--active")
                .show();

            $(".hamburger")
                .removeClass("active")
                .off();
            $(".c-header__menu").removeClass("c-header__menu--active");
            $(".c-menu-main__link-subtrigger").remove();
            $(".c-menu-main__link-l1-subtrigger").remove();
        }
    });
});

$(document).ready(function() {
/**
 * Switch preview image to Youtube video
 */

(function() {
    var c = {
        notinitialised: 0,
        loading: 1,
        ready: 2
    };

    var ytApiLoaded = c.notinitialised;
    var initPlayerEl = [];
    var players = {};

    init();

    window.onYouTubeIframeAPIReady = function() {
        ytApiLoaded = c.ready;
        for (var i = 0; i < initPlayerEl.length; i++) {
            addYtIframe(initPlayerEl[i]);
        }
        initPlayerEl = [];
    };

    function init() {
        var youtube = document.querySelectorAll(".youtube");
        for (var i = 0; i < youtube.length; i++) {
            youtube[i].addEventListener("click", function() {
                return initPlayer(this);
            });
        }
    }

    function initPlayer(el) {
        if (ytApiLoaded !== c.ready) {
            initPlayerEl.push(el);
        }

        if (ytApiLoaded < c.loading) {
            ytApiLoaded = c.loading;
            return addYtIFrameApiScriptTag();
        }
        addYtIframe(el);
    }

    function addYtIFrameApiScriptTag() {
        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    function addYtIframe(el) {
        var id = "youtube__video_player--" + el.dataset.ce;
        var contentId = "video_content--" + el.dataset.ce;
        var iframe = document.createElement("iframe");
        iframe.setAttribute("id", id);
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("allowfullscreen", "");
        iframe.setAttribute("allow", "autoplay");
        iframe.setAttribute(
            "src",
            "https://www.youtube-nocookie.com/embed/" +
            el.dataset.embed +
            "?rel=0&showinfo=0&autoplay=1&enablejsapi=1"
        );
        el.innerHTML = "";
        el.appendChild(iframe);

        players[id] = new YT.Player(id, {
            events: {
                onReady: getOnPlayerReady(contentId)
            }
        });
    }

    function getOnPlayerReady(contentId) {
        return function(e) {
            e.target.playVideo();
            var contentEl = document.getElementById(contentId);
            if (contentEl) {
                contentEl.addEventListener("click", getContentClick(e.target));
                contentEl.classList.remove("video_content--disabled");
                enableButtons(contentEl);
            }
        };
    }

    function enableButtons(contentEl) {
        var buttons = contentEl.getElementsByTagName("button");
        if (!buttons.length) {
            return;
        }
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].disabled = false;
        }
    }

    function getContentClick(player) {
        return function(e) {
            var time = e.target.dataset.time || e.target.parentNode.dataset.time;
            player.seekTo(time, true);
            player.playVideo();
        };
    }
})();





    $('.c-menu-submenu-watermark').bigtext();
    // $('.c-menu-submenu-watermark').fittext();

    /*
 * Glossar - Sprungmarken - Smooth Scrolling
 * Will be used in: src/pug/glossar/index.pug
 * Author: Michael Sandt
 * https://github.com/cferdinandi/smooth-scroll
 */
    $('.c-glossar__nav-item').on('click', function (){

        var scroll = new SmoothScroll('a[href*="#"]', {
            speed: 500,
            offset: function (anchor, toggle) {
                return 30;
                /*
                // Integer or Function returning an integer. How far to offset the scrolling anchor location in pixels
                // This example is a function, but you could do something as simple as `offset: 25`
                // An example returning different values based on whether the clicked link was in the header nav or not
                if (toggle.classList.closest('.my-header-nav')) {
                    return 25;
                } else {
                    return 50;
                }
                */
            }
        });
    });

    /*
     * Jquery Datepicker
     * Will be used in: src/pug/formular-elemente/index.pug
     * The ui styles and working jquery version is embeded in pug/_layout/layout.pug
     * Author: Michael Sandt
     * https://api.jqueryui.com/datepicker/
     */
    $("#datepicker").datepicker({
        prevText: '&#x3c;zurück', prevStatus: '',
        prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
        nextText: 'Vor&#x3e;', nextStatus: '',
        nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
        currentText: 'heute', currentStatus: '',
        todayText: 'heute', todayStatus: '',
        clearText: '-', clearStatus: '',
        closeText: 'schließen', closeStatus: '',
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
            'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
            'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        showMonthAfterYear: false,
        showOn: 'both',
        dateFormat: 'dd-mm-yy'
    });
    $(".ui-datepicker-trigger").css("display", "none");

    /*
 * Formular right button actions
 * Will be used in: src/pug/formular-elemente/index.pug
 */
    $('#password-input-button').on('click', function ( event ){
        var inputfield = document.getElementById("password-input");
        if (inputfield.type === "password") {
            inputfield.type = "text";
            inputfield.classList.remove("password-dots");
            inputfield.classList.add("password-readable");
        } else {
            inputfield.type = "password";
            inputfield.classList.remove("password-readable");
            inputfield.classList.add("password-dots");
        }
        inputfield.focus();
    });

    $('#password-input-mirror-button').on('click', function ( event ){
        var inputfield = document.getElementById("password-input-mirror");
        if (inputfield.type === "password") {
            inputfield.type = "text";
            inputfield.classList.remove("password-dots");
            inputfield.classList.add("password-readable");
        } else {
            inputfield.type = "password";
            inputfield.classList.remove("password-readable");
            inputfield.classList.add("password-dots");
        }
        inputfield.focus();
    });

    $('#success-error-input-button').on('click', function ( event ){
        console.log("x button is clicked!");
    });

});




